import { useState } from 'react';
import { IS_SERVER } from '../utils';

export const useCookie = (key: string, defaultValue = ''): [string | null, (value: any) => void, () => void] => {
  const getCookie = () => (IS_SERVER ? null : localStorage.getItem(key) || defaultValue);
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value: any) => {
    setCookie(value);
    IS_SERVER ? null : localStorage.setItem(key, value);
  };

  const removeCookie = () => {
    IS_SERVER ? null : localStorage.removeItem(key);
  };

  return [cookie, updateCookie, removeCookie];
};
